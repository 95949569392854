// tympanus.net/codrops/2013/12/18/perspective-page-view-navigation/
.perspective {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &--modalview {
    position: fixed;
    perspective: 1500px; } }

.container {
  position: relative;
  transform: translateZ(0) translateX(0) rotateY(0deg);
  min-height: 100%;
  outline: 30px solid $highlight;
  transition: transform .4s; }

.modalview {

  .container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    backface-visibility: hidden; } }

.effect-rotate-left {

  .container {
    transform-origin: 0% 50%;
    transition: transform .4s; }

  &--animate {

    .container {
      transform: translateZ(-1800px) translateX(-50%) rotateY(45deg);
      outline: 30px solid $highlight; } } }

.outer-nav {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translateY(-50%);
  transform-style: preserve-3d;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  visibility: hidden;
  transition: visibility 0s .2s;

  &.is-vis {
    visibility: visible; }

  &--return {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    cursor: pointer;
    z-index: 11;

    &.is-vis {
      display: block; } }

  & > li {
    transform-style: preserve-3d;
    transform: translateX(350px) translateZ(-1000px);
    font-size: 55px;
    font-weight: 900;
    opacity: 0;
    cursor: pointer;
    transition: transform .2s, opacity .2s;

    &.is-vis {
      transform: translateX(0) translateZ(0);
      opacity: 1;
      transition: transform .4s, opacity .4s; }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -25%);
      width: 110%;
      height: 15px;
      opacity: 0;
      background-color: $highlight; }

    &.is-active::before {
      opacity: 1; }

    &:hover {
      color: $highlight; }

    &.outer-nav__parent.is-active::before {
      top: 22%; }

    @media (max-width: 767px) {
      font-size: 44px; }

    @media (max-width: 600px) {
      font-size: 24px; } }

  li.is-vis:nth-child(2) {
    transition-delay: .04s; }

  li.is-vis:nth-child(3) {
    transition-delay: .08s; }

  li.is-vis:nth-child(4) {
    transition-delay: .12s; }

  li.is-vis:nth-child(5) {
    transition-delay: .16s; } }

.outer-subnav {
  display: block;
  list-style: none;

  li {
    font-size: 20px;
    color: #fff;

    &:hover {
      color: $highlight; } } }

.outer-subnav_hidden {
  display: none; }

.is-active .outer-subnav {
  display: block; }

.is-sub-active {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -25%);
    width: 45%;
    height: 7px;
    opacity: 1;
    background-color: $highlight; } }
