// This is the single file output by sass. It is intended to ONLY @import other files.


// BASE
@import 'base/fonts';
@import 'base/normalize';
@import 'base/vars';
@import 'base/selection-colors';
@import 'base/body-element';
@import 'base/forms.scss';


// LAYOUTS
@import 'layouts/grid';


// MODULES
@import 'modules/device-notification';
@import 'modules/section';
@import 'modules/header';
@import 'modules/side-nav';
@import 'modules/intro';
@import 'modules/work';
@import 'modules/about';
@import 'modules/contact';
@import 'modules/hire';
@import 'modules/outer-nav';
@import 'modules/docs.scss';
@import 'modules/vacancies.scss';
@import 'modules/philosophy.scss';
@import 'modules/history.scss';

// PLUGINS
@import 'plugins/swiper.sass';
@import 'plugins/dialoggo.sass';
@import 'plugins/preloader.scss';
