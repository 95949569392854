.philosophy {
	position: relative;
    display: block;
    width: 700px;
    height: 80vh;
    max-width: 75%;
    padding-top: 40px;
    margin: 0 auto;

    @media (max-width: 600px) {
        max-width: 95%;
    }

    h1 {
		position: relative;
		font-size: 48px;
		font-weight: 900;
		line-height: 1;
		z-index: 1;

        @media (max-width: 600px) {
            font-size: 32px;
        }
    }

    p {
    	font-size: 18px;
    }

    strong {
    	font-size: 32px;
    	font-weight: bold;
    	text-transform: uppercase;
    	color: $highlight;
        @media (max-width: 600px) {
            font-size: 22px;
        }
    }

    &--container {
    	height: 100%;
    	padding-top: 40px;
    	padding-bottom: 20px;
    }
}

