// reference media queries at the bottom of this file
.device-notification {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $black;
  z-index: 12;

  &--logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $white;

    p {
      margin: 0 0 0 10px;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase; } }

  &--message {
    width: 70%;
    margin: 30px 0 0 0;
    font-weight: 700;
    text-align: center; } }
