.work {
  position: relative;
  display: flex;
  width: 960px;
  max-width: 80%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 1180px) {
    max-width: 100%; }

  h2 {
    margin: 0 0 20px 0;
    font-size: 30px;
    text-align: center; }

  &--lockup {
    position: relative;
    width: 100%;
    height: 324px;

    .slider {
      list-style: none;
      padding-left: 0;

      &--item {
        text-align: center;

        a {
          text-decoration: none;
          color: #858585; }

        &-title {
          margin-top: 10px;
          font-size: 20px;
          font-weight: 400;
          line-height: 20px;
          color: #fff;
          margin-bottom: 8px;

          small {
            display: block;
            font-size: 14px;
            color: #858585; } }

        &-description {
          display: block;
          max-width: 250px;
          margin: 0 auto; }

        &-image {
          width: 200px;
          height: 200px;
          margin: 0 auto;
          border-radius: 50%;
          overflow: hidden;
          transition: all 150ms ease-out;
          border: 3px solid transparent;

          img {
            width: 100%; } }

        &-left {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: block; }

        &-right {
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          display: block; }

        &-center {
          position: relative;
          top: 30px;
          left: 50%;
          transform: translateX(-50%);
          display: block;

          a {
            color: $white; }

          .slider--item-title {
            margin-top: 25px;
            font-size: 16px; }

          .slider--item-description {
            display: block; }

          .slider--item-image {
            width: 200px;
            height: 200px; } } } }

    .slider--next,
    .slider--prev {
      position: absolute;
      top: 160px;
      display: flex;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      background-color: $muted-gray;
      border-radius: 50%;
      cursor: pointer;
      z-index: 100;

      svg {
        width: 20px;
        fill: $white; } }

    .slider--next:hover,
    .slider--prev:hover {
      background-color: $highlight; }

    .slider--next {
      right: 0; }

    .slider--prev {
      left: 0; } } }

.js__dialog-link:hover .slider--item-image {
  border-color: $highlight; }

.js__dialog-link:hover .slider--item-title {
  color: $highlight; }

@media (max-width: 900px) {

  .work {

    &--lockup {

      .slider {

        &--item {

          &-image {
            width: 120px;
            height: 120px; }

          &-center {

            .slider--item-image {
              width: 240px;
              height: 240px; } } } }

      .slider--next,
      .slider--prev {
        top: 130px; } } } }

@media (max-width: 767px) {

  .work {

    &--lockup {

      .slider {
        width: 75%;

        &--item {

          &-image {
            width: 200px;
            height: 200px; }

          &-center {

            .slider--item-image {
              width: 200px;
              height: 200px; } } } }

      .slider--next,
      .slider--prev {
        top: 105px; } } } }

@media (max-width: 600px) {

  .work {

    h2 {
      font-size: 22px; }

    &--lockup {

      .slider {
        width: auto;

        &--item {

          &-left,
          &-right {
            display: none; } } } } } }
