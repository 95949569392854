.l-viewport {
  position: relative;
  width: 100%;
  height: 100vh;
  box-shadow: 0 0 45px 5px rgba(0,0,0,.85);
  overflow: hidden; }

.l-wrapper {
  position: relative;
  width: 1440px;
  max-width: 90%;
  height: 100%;
  margin: 0 auto; }

.lazy-load-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }

.l-side-nav {
  position: absolute;
  left: 0;
  display: flex;
  height: 100%;
  align-items: center;

  // placed on layout class due to firefox bug
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 2px;
    height: 70%;
    max-height: 750px;
    background-color: #555;
    opacity: .35;
    z-index: 10; }

  @media (max-width: 1180px) {
    display: none; } }

.l-main-content {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none; }

.l-section {
  position: absolute;
  width: 100%;
  height: 100%; }
