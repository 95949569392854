.hire {
  position: relative;
  display: flex;
  width: 700px;
  max-width: 75%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 1180px) {
    max-width: 100%; }

  h2 {
    margin: 0 0 20px 0;
    font-size: 30px;
    text-align: center; } }

.work-request {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: $white;

  input[type="submit"] {
    width: 400px;
    max-width: 100%;
    line-height: 50px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: $highlight;
    border: none;
    border-radius: 0;

    &:focus {
      outline: none; } }

  &--options {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;

    .options-a {
      display: flex;
      width: 100%;
      justify-content: space-between; }

    .options-b {
      display: flex;
      width: 72%;
      flex-wrap: wrap;
      justify-content: space-around; }

    label {
      display: block;
      width: 200px;
      margin-bottom: 30px;
      line-height: 50px;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      border: 2px solid $white;
      cursor: pointer;
      transition: background-color .2s ease-in-out, border-color .2s ease-in-out;

      svg {
        position: relative;
        left: -5px;
        width: 0;
        fill: $white;
        transition: width .2s ease-in-out; } }

    input[type="checkbox"] {
      display: none;

      &:checked + label {
        background-color: $highlight;
        border-color: $highlight;

        & svg {
          width: 15px; } } } }

  &--information {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 60px;

    .information-name,
    .information-email {
      position: relative;
      width: 45%;
      height: 50px;
      font-size: 30px;
      font-weight: 300; }

    input[type="text"],
    input[type="email"] {
      width: 100%;
      padding: 0 0 5px 0;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $white;
      border-radius: 0;

      &:focus {
        outline: none;
        background-color: $black; } }

    label {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      transition: top .2s ease-in-out, font-size .2s ease-in-out; }

    input:focus + label,
    input.has-value + label {
      top: -15px;
      font-size: 14px; } } }

@media (max-width: 767px) {

  .work-request {

    &--options {
      flex-direction: row;
      justify-content: space-around;

      .options-a,
      .options-b {
        display: block;
        width: auto; } } } }

@media (max-width: 600px) {

  .hire h2 {
    font-size: 20px;
    margin-top: 50px;
    text-align: center;
    margin-bottom: 8px; }

  .work-request {

    &--options {
      margin: 20px 0;

      @media (max-width: 415px) {
        justify-content: space-between; }

      label {
        width: 138px;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 40px; }

      input[type="checkbox"] {

        &:checked + label {

          & svg {
            width: 12px; } } } }

    &--information {
      margin-bottom: 30px;

      .information-name,
      .information-email {
        height: 40px;
        font-size: 24px; } } } }
