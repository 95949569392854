.vacancie {
    position: relative;
    display: flex;
    width: 900px;
    max-width: 75%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    &--wrapper {
        position: relative;
        height: 475px;

        h1 {
            position: relative;
            font-size: 48px;
            font-weight: 900;
            line-height: 1;
            z-index: 1;
        }
    }

    &--item {
        padding: 24px;
        border: 1px solid $muted-gray;
    }

    &--title {
        font-size: 28px;
        color: $highlight;
        display: inline-block;
        vertical-align: top;
    }

    &--price {
        display: inline-block;
        vertical-align: top;
        float: right;
        margin-top: 8px;
        font-size: 24px;
    }

    &--btn-more {
        position: relative;
        padding: 5px 17px 5px 12px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        color: #fff;
        margin-bottom: 8px;
    }

    &--btn-bg {
        position: absolute;
        top: 0;
        left: 23px;
        right: 0;
        height: 100%;
        background-color: $highlight;
        z-index: -1;
        transition: left .2s ease-in-out;
    }

    &--btn-more:hover .vacancie--btn-bg {
        left: 0;
    }

}

.vacancie-info {

    h2 {
        display: inline-block;
        vertical-align: top;
    }

    &--price {
        display: inline-block;
        vertical-align: top;
        float: right;
        padding: 15px 30px;
        font-size: 24px;
    }

    &--send {
        position: relative;
        padding: 5px 17px 5px 12px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        color: #fff;
        margin-bottom: 8px;
        z-index: 1;
    }

    &--btn-bg {
        position: absolute;
        top: 0;
        left: 23px;
        right: 0;
        height: 100%;
        background-color: $highlight;
        z-index: -1;
        transition: left .2s ease-in-out;
    }

    &--send:hover .vacancie-info--btn-bg {
        left: 0;
    }

}

@media (max-width: 600px) {

    .vacancie {
        max-width: 90%;

        &--wrapper {

            h1 {
                font-size: 32px;
            }

        }

        &--title {
            line-height: normal;
        }

        &--price {
            float: none;
            display: block;
        }

        &--btn-more {
            display: block;
        }
    }

}
