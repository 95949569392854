.form {
	display: block;

	& * {
		box-sizing: border-box;
	}

	&__row {
		display: block;
		margin-bottom: 16px;

		&_style_submit {
			text-align: right;
		}
	}

	&__label {
		cursor: pointer;
		padding-left: 10px;
	}

	&__input {
		display: block;
		padding: 10px;
		font-size: 18px;
		border: 1px solid #fff;
		outline: 0;
		background: none;
		width: 100%;
		transition: all .2s ease-in-out;

		&:focus {
			border-color: #ff7200;
		}

	}

	&__submit {
		position: relative;
		padding: 5px 17px 5px 12px;
		font-weight: 700;
		text-transform: uppercase;
		background-color: transparent;
		border: none;
		cursor: pointer;
		display: inline-block;
		text-decoration: none;
		color: #fff;
		margin-bottom: 8px;
		z-index: 1;

		&::after {
		    position: absolute;
		    top: 0;
		    content: '';
		    display: block;
		    left: 23px;
		    right: 0;
		    height: 100%;
		    background-color: #ff7200;
		    z-index: -1;
		    transition: left .2s ease-in-out;
		}

		&:hover::after {
			left: 0;
		}
	}

	&__private {
		font-size: 14px;
		color: #999;
		margin-top: 8px;

		a.js__dialog-link {
			text-transform: none;
			font-size: 14px;
			font-weight: normal;
			color: #999;
			padding: 0;
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.input__fail {
	border-color: red !important;
}

@media (max-width: 600px) {

	.form {

		&__private {
			font-size: 12px;

			a.js__dialog-link {
				font-size: 12px;
			}
		}

	}

}