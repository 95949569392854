.dialog-overlay {
	display: none;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.85);
	width: 100%;
	height: 100%;
	text-align: center;
	overflow-y: auto;
	padding: 20px 0;
	box-sizing: border-box; }

.dialog-overlay_open {
	display: block;
	opacity: 1; }


.dialog-overlay:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%; }


.dialog-modal {
	display: inline-block;
	vertical-align: middle;
	width: 480px;
	max-width: 100%;
	opacity: 0;
	transition: all 200ms ease-in;
	background-color: #000;
	padding: 16px;
	min-height: 100px;
	cursor: default;
	position: relative;
	box-sizing: border-box;
	border: 10px solid $highlight; }


.dialog-modal_open {
	opacity: 1; }

.dialog-size-md {
	width: 800px;
	max-width: 100%; }

.dialog-open {
	overflow: hidden; }

.dialog-target-case {
	width: 800px;
	max-width: 100%; }

.dialog-close {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	width: 20px;
	height: 20px;

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 10px;
		left: 0;
		width: 20px;
		height: 1px;
		background-color: #fff;
		transform: rotate(45deg); }

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 10px;
		left: 0;
		width: 20px;
		height: 1px;
		background-color: #fff;
		transform: rotate(-45deg); } }

.dialog-content {
	color: #fff;
	text-align: left;

	h3 {
		font-size: 20px;
		text-transform: uppercase; }

	a {
	    position: relative;
	    padding: 5px 17px 5px 12px;
	    font-weight: 700;
	    text-transform: uppercase;
	    background-color: transparent;
	    border: none;
	    color: #fff;
	    display: inline-block;
	    margin-bottom: 8px;
	    z-index: 1;
	    text-decoration: none; }

	svg {
		position: relative;
		left: 5px;
		width: 15px;
		fill: #fff; } }

.img-case {
	display: inline-block;
	vertical-align: top;
	margin-right: 16px;
	width: 300px;
	max-width: 100%; }

.js__dialog-link {
	cursor: pointer; }

.dialog-btn-overlay {
    top: 0;
    left: 23px;
    right: 0;
    height: 100%;
    position: absolute;
    background-color: $highlight;
    z-index: -1;
    transition: all 150ms ease-in-out; }

a:hover .dialog-btn-overlay {
	left: 0; }

.case-info__description {
	display: inline-block;
	vertical-align: top;
	width: 400px;
	max-width: 100%; }

.dialog-title {
	font-size: 24px; }
