.download-pdf {
    position: relative;
    padding: 5px 17px 5px 12px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    margin-bottom: 8px;

    svg {
    	position: relative;
	    left: 5px;
	    width: 15px;
	    fill: #fff;
    }

    .btn-background {
    	position: absolute;
	    top: 0;
	    left: 23px;
	    right: 0;
	    height: 100%;
	    background-color: $highlight;
	    z-index: -1;
	    transition: left .2s ease-in-out;
    }

    &.additive_file .btn-background {
    	background-color: $muted-gray;
    }

    &:hover .btn-background {
    	left: 0;
    }
}

.docs {
    position: relative;
    display: flex;
    width: 900px;
    max-width: 75%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}

.docs--wrapper {
    position: relative;
    height: 475px;

    &::before {
		content: "";
		position: absolute;
		bottom: 20px;
		left: -15px;
		right: 0;
		height: 2px;
		background-color: $muted-gray;
    }

    &::after {
		content: "";
		position: absolute;
		bottom: 18px;
		left: 0;
		width: 30px;
		height: 4px;
		background-color: $highlight;
    }

    h1 {
		position: relative;
		font-size: 48px;
		font-weight: 900;
		line-height: 1;
		z-index: 1;
    }
}

@media (max-width: 600px) {

    .docs {
        max-width: 90%;

        &--wrapper {

            h1 {
                font-size: 32px;
            }

        }
    }

    .download-pdf {
        display: block;
    }

}
